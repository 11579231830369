import React from "react";

const NotFoundPage = () => 
    (
        <>
             <div className="notFoundPage text-center">
                <h1>404</h1>
                <p> Page not found :(</p>
             </div>
        </>
    )

export default NotFoundPage;