import React from 'react'
import './App.scss';
import { Route, Routes } from 'react-router-dom'; 
import NavBar from './components/NavBar';
import HomePage from './assets/pages/HomePage';
import AboutPage from './assets/pages/AboutPage';
import PortfolioPage from './assets/pages/PortfolioPage';
import NotFoundPage from './assets/pages/NotFoundPage';
import WorkDetailPage from './assets/pages/WorkDetailPage';



function App() {
  return (
    <>
    <div className='app'>
      <NavBar/>
      <div className='container'>
        <Routes>
          <Route path="/" element={<HomePage />}/>  
          <Route path="/about" element={<AboutPage />}/>
          <Route path="/portfolio" element={<PortfolioPage />}/>
          <Route path="/portfolio/:workSlug" element={<WorkDetailPage />}/>       
          <Route path="*" element={<NotFoundPage />}/>  
      </Routes>
      </div>
    </div>
    </>
  );
}

export default App;
