import React from 'react'
import './index.scss'
import { Link } from 'react-router-dom'
import logo from '../../assets/img/logo.png'

const NavBar = () => {
    return (
        <div className='container-fluid'>
            <header className='d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4'>
                <Link to={"/"} className="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-dark text-decoration-none">
                    <img className='logo' src={logo} alt="logo"/>
                </Link>
               {/*
                <ul className='nav col-12 col-md-auto mb-2 px-4 justify-content-center mb-md-0'>
                    <li><Link to={"/"} className="nav-link px-2 link-secondary">Home</Link></li>
                    <li><Link to={"/about"} className="nav-link px-2 link-secondary">About</Link></li>
                    <li><Link to={"/portfolio"} className="nav-link px-2 link-secondary">Portfolio</Link></li>
                </ul>
                */}
                </header>
        </div>
    )
}

export default NavBar