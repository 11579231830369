import React from "react";
import { Link } from "react-router-dom";
import logoGtb from '../../assets/img/220px-Logo_of_GTB.png';
import logoAccenture from '../../assets/img/Accenture-logo.png';
import logoFord from '../../assets/img/Ford_logo_flat.png';
import logoSpark44 from '../../assets/img/spark44_logo.jpg';
import logoTBB from '../../assets/img/logo-TBB.jpeg';
import logoTheAndP from '../../assets/img/thepartnership_logo.jpg';
import logoVmlYRC from '../../assets/img/vmlyr_commerce_logo.jpeg';
import logoVMLYR from '../../assets/img/vmlyr_logo.png';
import { Helmet } from 'react-helmet';

const HomePage = () => {
    return (
            <>
            <Helmet>
            <title>Gianluca Bracchitta | Digital Production for Agencies</title>
                <meta id="meta-description" name="description" content="Freelances services. If you need: fullstack development | ad tech / ad ops | digital advertising consultancy | digital advertising production | digital campaigns troubleshooting | direct email templates production | digital project management | digital automation and ai" />
                <meta id="og-title" property="og:title" content="Gianluca Bracchitta | Digital Production for Agencies" />
            </Helmet>
            <div className="row">
                 <div className="col">
                    <section id="first" className="my-4 p-4 text-center">
                    
                        <div className="row blue-bg">
                            <div className="container">
                            <section className="rw-wrapper">
                                <h2 className="rw-sentence">					
                                    <span>it's not just</span>
                                    <div className="rw-words rw-words-1">
                                    <span>fullstack development&nbsp;</span>
                                    <span>ad tech / ad ops&nbsp;</span>
                                    <span>digital advertising consultancy&nbsp;</span>
                                    <span>digital advertising production&nbsp;</span>
                                    <span>digital campaigns troubleshooting</span>
                                    <span>direct email templates production&nbsp;</span>
                                    <span>digital project management&nbsp;</span>
                                    <span>digital automation and ai&nbsp;</span>
                                    </div>
                                </h2>
                            </section>
                            </div>
                        </div>
   
                    </section>
                    <section id="second" className="p-4 mt-4 text-center gray-to-white"><br />fullstack development | ad tech / ad ops | digital advertising consultancy | digital advertising production | digital campaigns troubleshooting | direct email templates production | digital project management | digital automation and ai </section>
                    <section id="addresses-section" className="p-4 text-center">
                        <div className="addresses text-center my-2">
                        <p className="piccolo-testo">Produzione Digital per le Agenzie di comunicazione - Digital Production for Agencies <br />
                        
                        <a href="mailto:gianluca@produzionedigital.it">gianluca@produzionedigital.it</a> <br />
      		                Gianluca Bracchitta <br />
                        <a href="https://www.linkedin.com/in/gianlucabracchitta/" rel="noreferrer" title="Linkedin Profile" target="_blank">linkedin.com/in/gianlucabracchitta/</a><br />
                            00136 Rome (Italy) <br />
      		                p.iva IT 152 88 631003<br />
                        </p>
                        </div>
                    </section>
                    <section id="clients-section my-2">
                        <div className="clients text-center">
                            <span className="">my clients:</span><br />
                            <div className="clientslogo">
                                <div className="row d-flex flex-wrap align-items-center mx-3">
                                    <div className="col text-center"><img className='client-logo' src={logoGtb} alt="logo GTB"/></div>
                                    <div className="col text-center"><img className='client-logo' src={logoAccenture} alt="logo Accenture"/></div>
                                    <div className="col text-center"><img className='client-logo' src={logoFord} alt="logo Ford"/></div>
                                    <div className="col text-center"><img className='client-logo' src={logoSpark44} alt="logo Spark44"/></div>
                                    <div className="col text-center"><img className='client-logo' src={logoTheAndP} alt="logo The And Partnership"/></div>
                                    <div className="col text-center"><img className='client-logo' src={logoTBB} alt="logo The Big Band"/></div>
                                    <div className="col text-center"><img className='client-logo' src={logoVMLYR} alt="logo VMLY&R"/></div>
                                    <div className="col text-center"><img className='client-logo' src={logoVmlYRC} alt="logo VMLY&R Commerce"/></div>    
                                </div>   
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            </>
             
    )
}

export default HomePage;