import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import NotFoundPage from "./NotFoundPage";


const WorkDetailPage = () => {
    const { workSlug } = useParams();
    const [workInfo, setWorkInfo] = useState({});
    useEffect(() => {

        const loadWorkInfo = async () => {
            const response = await axios.get(`/api/works/${workSlug}`);
            const newWorkInfo = response.data;
            setWorkInfo(newWorkInfo);
        };

        loadWorkInfo();
    }, []);


     if (!workInfo) {
         return <NotFoundPage />    
    } 

    return (
        <>
        <small>{workInfo.date}</small>
        <h1>{workInfo.nome} detail page</h1>
        <p>{workInfo.description}</p>
        <p>{workInfo.company} | {workInfo.sector}</p>
        </>
        
    )
}

export default WorkDetailPage;